@media (min-width: 641px) {

  .carousel {
    width: 450px;
  }

  .single_image {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }

  .carousel__image {
    height: auto;
    width: 100%;
    margin: auto;
  }

  .carousel__image__single {
    max-height: 90vh;
    width: auto;
  }
}

@media (min-width: 1600px) {
  .carousel {
    width: 550px;
  }
}

@media (max-width: 640px) {
  .carousel {
    width: 90%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }

  .flickity-button {
    display: none;
  }

  .carousel__image {
    width: 100%;
    margin: auto;
    max-width: 600px;
  }
  
  .carousel__image__single {
    max-height: 400px;
    width: auto;
    max-width: 1000px;
  }
}