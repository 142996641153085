.header {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  top: 0;
  z-index: 100;
  position: fixed;
}

.socials {
  position: absolute;
  right: 20px;
  top: 42px;
  z-index: 50;
}

.socials a {
  -webkit-transition: .3s ease;
  -o-transition: .3s ease;
  transition: .3s ease;
}

.socials > a > img {
  width: 18px;
  margin-right: 10px;
  height: 18px;
}

.socials a:nth-of-type(1) img {
  -webkit-filter: invert(100%);
          filter: invert(100%);
}

.socials__amies {
  cursor: pointer;
}

.socials__amies img {
  margin-right: 10px;
  width: 14px;
  z-index: 50;
  height: 18px;
}

.friends__cross {
  position: absolute;
  z-index: 80;
  cursor: pointer;
  top: 40px;
  right: 50px;
  display: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 40px;
  width: 40px;
}

.friends__cross > div {
  height: 2px;
  width: 40px;
  background-color: rgba(0,0,0,0.9);
  -webkit-transition: .3s ease;
  -o-transition: .3s ease;
  transition: .3s ease;
}

.friends__cross > div:nth-of-type(1) {
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
}

.friends__cross > div:nth-of-type(2) {
  -webkit-transform: translateY(-1px) rotate(135deg);
      -ms-transform: translateY(-1px) rotate(135deg);
          transform: translateY(-1px) rotate(135deg);
}

.friends__cross:hover > div:nth-of-type(1) {
  -webkit-transform: rotate(135deg);
      -ms-transform: rotate(135deg);
          transform: rotate(135deg);
}

.friends__cross:hover > div:nth-of-type(2) {
  -webkit-transform: translateY(-1px) rotate(45deg);
      -ms-transform: translateY(-1px) rotate(45deg);
          transform: translateY(-1px) rotate(45deg);
}


/****** Mobile ******/

@media (max-width: 800px) {

  .header {
    height: 100vh;
    max-width: 100%;
    position: absolute;
    overflow: hidden;
  }

  .socials {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    right: 10px;
    top: 20px;
  }

  .socials > a > img {
    width: 22px;
    height: 20px;
  }

  .socials__amies img {
    margin-right: 0px;
    margin: 10px 0;
    width: 22px;
    height: 24px;
  }

  .friends__cross {
    top: 40px;
    right: 10px;
  }
}