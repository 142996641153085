.missions {
  min-height: calc(100vh - 100px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.pictos {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 80%;
  padding: 60px 0;
  z-index: 30;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-flex: 2;
      -ms-flex: 2;
          flex: 2;
}

.mission__shadow {
  position: absolute;
  width: 100%;
  height: calc(100vh - 100px);
  z-index: 50;
}

.mission__bottom {
  text-align: center;
  font-family: 'Microsoft Yi Baiti';
  font-size: 20pt;
  font-weight: 600;
  padding-bottom: 10px;
}


/****** Mobile ******/

@media (max-width: 640px) {

  .missions {
    height: auto;
    margin-bottom: 20px;
  }

  .mission__shadow {
    height: auto;
  }

  .pictos {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 0;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }

  .mission__bottom {
    padding: 0 30px;
    font-size: 18px;
  }
}