@font-face {
  font-family: 'SF Movie Poster';
  src: url('./assets/fonts/SF_Movie_Poster.ttf');
  line-height: 1;
}

@font-face {
  font-family: 'Microsoft Yi Baiti';
  src: url( './assets/fonts/Microsoft_Yi_Baiti.ttf');
  line-height: 1;
}

body {
  margin: 0;
  padding: 0;
  font-family: Arial;
  overflow-x: hidden;
  height: 100%;
}

.header-bg {
  width: 100%;
  height: 90px;
  position: fixed;
  background: url('./assets/images/wall.jpg');
  background-position: top;
  background-size: cover;
  z-index: 40;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

/* ScrollBar */

@media (min-width: 642px) {
  ::-webkit-scrollbar {
    width: 8px;
  }
  
  ::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 5px #fff; */
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #ababab;
    border-radius: 10px;
  }
}


@media (max-width: 640px) {
  * {
		-webkit-box-sizing: border-box;
		        box-sizing: border-box;
	}
	
	body {
    height: calc(var(--vh, 1vh) * 100);
		margin: 0;
    padding: 0;
	}
	
	img,
	table,
	td,
	blockquote,
	code,
	pre,
	textarea,
	input,
	iframe,
	object,
	embed,
	video {
		max-width: 100%;
	}
}

@media (max-width: 800px) {
  .hide_mobile {
		display: none !important;
	}
}

@media (min-width: 800px) {
  .hide_desktop {
    display: none;}
}