.projets {
  background: #fff;
  height: calc(100vh - 100px);
  -webkit-box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.3);
          box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
}

.projets__shadow {
  position: absolute;
  width: 100%;
  height: calc(100vh - 100px);
}

.projects__container {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 3%;
  height: 40%;
  width: 100%;
  padding: 0 10%;
}

.projects__container > a {
  z-index: 30;
}


/****** Mobile ******/

@media (max-width: 640px) {
  .projets {
    height: 100vh;
  }

  .projets__shadow {
    height: 100vh;
  }

  .projects__container {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 0px 1fr;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    height: 65%;
    padding: 50px 20px;
  }
}