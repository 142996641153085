.home {
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.home__shadow {
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 50;
  top: 0;
}

.home__logo {
  width: 600px;
  z-index: 30;
  -webkit-transform: translateY(40px);
      -ms-transform: translateY(40px);
          transform: translateY(40px);
  -webkit-filter: drop-shadow(1px 4px 2px rgba(0,0,0,0.4));
          filter: drop-shadow(1px 4px 2px rgba(0,0,0,0.4));
}

.home__la {
  width: 600px;
  position: absolute;
  -webkit-transform: translateY(40px);
      -ms-transform: translateY(40px);
          transform: translateY(40px);
  opacity: 0;
}

.la-animation {
  -webkit-animation: la 3.6s 0.5s ease forwards;
          animation: la 3.6s 0.5s ease forwards;
}

.home__catch {
  width: 600px;
  position: absolute;
  -webkit-transform: translateY(40px);
      -ms-transform: translateY(40px);
          transform: translateY(40px);
  opacity: 0;
}

.catch-animation {
  -webkit-animation: catch 1.5s 0.5s cubic-bezier(0, -0.4, 0.42, 0.68) forwards;
          animation: catch 1.5s 0.5s cubic-bezier(0, -0.4, 0.42, 0.68) forwards;
}

@-webkit-keyframes la {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes la {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes catch {
  0% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(40px);
            transform: translateY(40px);
    opacity: 1;
  }
}

@keyframes catch {
  0% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(40px);
            transform: translateY(40px);
    opacity: 1;
  }
}

@media (max-width: 640px) {
  .home {
    height: 100vh;
  }

  .home__shadow {
    height: auto;
  }

}