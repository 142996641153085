.mission {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  text-align: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  z-index: 40;
  height: 240px;
  width: 180px;
}

.mission_img {
  height: 150px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 20px;
}

.mission img {
  width: 105px;
}

.mission div {
  font-family: 'SF Movie Poster';
  font-size: 36pt;
  letter-spacing: 4px;
  width: 99%;
  line-height: 0.9;
  text-align: center;
}

@media (max-width: 640px) {
  .mission {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    min-height: 0;
    margin-bottom: 20px;
  }

  .mission_img {
    height: 150px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 5px;
  }

  .mission img {
    width: 80px;
    margin-bottom: 10px;
  }

  .mission div {
    font-size: 23pt;
    letter-spacing: 4px;
    max-width: 150px;
  }

  .mission:nth-of-type(2n)  {
    -webkit-transform: translateX(-70px);
        -ms-transform: translateX(-70px);
            transform: translateX(-70px);
  }
  .mission:nth-of-type(2n + 1)  {
    -webkit-transform: translateX(70px);
        -ms-transform: translateX(70px);
            transform: translateX(70px);
  }
}