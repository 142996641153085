.burger {
  position: absolute;
  top: 20px;
  left: 20px;
}

.burger .lines {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  margin-bottom: 20px;
}

.burger .lines span {
  height: 2px;
  width: 40px;
  margin: 4px;
  background: #000000;
  border-radius: 10px;
  -webkit-transition: .5s ease;
  -o-transition: .5s ease;
  transition: .5s ease;
  -webkit-transform-origin: left;
      -ms-transform-origin: left;
          transform-origin: left;

}

.burger__first {
  width: 40px;
  height: 2px;
  -webkit-transform: rotate(45deg) translateY(-6px);
      -ms-transform: rotate(45deg) translateY(-6px);
          transform: rotate(45deg) translateY(-6px);
}

.burger__second {
  width: 40px;
  height: 2px;
  -webkit-transform: scaleX(0);
      -ms-transform: scaleX(0);
          transform: scaleX(0);
  opacity: 0;
}

.burger__third {
  width: 40px;
  height: 2px;
  -webkit-transform: rotate(-45deg) translateY(6px);
      -ms-transform: rotate(-45deg) translateY(6px);
          transform: rotate(-45deg) translateY(6px);
}

.burger__menu {
  -webkit-transition: 0.5s ease;
  -o-transition: 0.5s ease;
  transition: 0.5s ease;
  opacity: 0;
  -webkit-transform: translateY(-40px);
      -ms-transform: translateY(-40px);
          transform: translateY(-40px);
  pointer-events: none;
  font-family: 'SF Movie Poster';
  font-size: 24pt;
  line-height: 21.6pt;
  letter-spacing: 2px;
}

.burger__menu li {
  margin-bottom: 5px;
}

.burger__menu a {
  color: #000000;
}

.visible {
  opacity: 1;
  -webkit-transform: translateY(0px);
      -ms-transform: translateY(0px);
          transform: translateY(0px);
  pointer-events: auto;
}