.single-project {
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  padding: 0 3vw;
  font-family: 'Microsoft Yi Baiti';
  background: #fff;
}

.closing-cross {
  position: absolute;
  top: 30px;
  right: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 40px;
  width: 40px;
}

.closing-cross > div {
  height: 2px;
  width: 40px;
  background-color: rgba(0,0,0,0.9);
  -webkit-transition: .3s ease;
  -o-transition: .3s ease;
  transition: .3s ease;
}

.closing-cross > div:nth-of-type(1) {
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
}

.closing-cross > div:nth-of-type(2) {
  -webkit-transform: translateY(-1px) rotate(135deg);
      -ms-transform: translateY(-1px) rotate(135deg);
          transform: translateY(-1px) rotate(135deg);
}

.closing-cross:hover > div:nth-of-type(1) {
  -webkit-transform: rotate(135deg);
      -ms-transform: rotate(135deg);
          transform: rotate(135deg);
}

.closing-cross:hover > div:nth-of-type(2) {
  -webkit-transform: translateY(-2px) rotate(45deg);
      -ms-transform: translateY(-2px) rotate(45deg);
          transform: translateY(-2px) rotate(45deg);
}

.single-project__left {
  -webkit-box-flex: 1.8;
      -ms-flex: 1.8;
          flex: 1.8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.previous {
  -webkit-transform: translate(-80px, -10px);
      -ms-transform: translate(-80px, -10px);
          transform: translate(-80px, -10px);
}

.next {
  -webkit-transform: translate(80px, -10px);
      -ms-transform: translate(80px, -10px);
          transform: translate(80px, -10px);
}

.single-project__next {
  position: absolute;
  bottom: 15vh;
  font-size: 22px;
}


.single-project__next > img {
  width: 80px;
}

.single-project__next:hover > img {
  opacity: 0.9;
}

.single-project__right {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-top: 15vh;
}

.project-title {
  font-size: 24px;
  margin: 0 0 10px 0;
  text-align: left;
}

.project-description {
  width: 50%;
  font-size: 20px;
}

.project-description p {
  margin: 0;
}


/****** Mobile ******/

@media (max-width: 640px) {
  .single-project {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    padding: 100px 5vw;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    height: auto;
    min-height: 100vh;
  }

  .single-project__next {
    position: relative;
    bottom: 0;
    left: -20px;
    align-self: flex-end;
    text-align: end;
    font-size: 28px;
  }

  .single-project__right {
    -webkit-box-flex: 0;
        -ms-flex: none;
            flex: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    padding-top: 0;
  }
  
  .project-title {
    font-size: 28px;
    width: 90%;
  }
  
  .project-description {
    width: 90%;
    font-size: 20px;
  }
}