.menu {
  z-index: 100;
}

.menu ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.menu li {
  margin: 0 16px;
  font-family: 'SF Movie Poster';
  font-size: 24pt;
  line-height: 21.6pt;
  letter-spacing: 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.menu a {
  color: #000;
}

.menu li span {
  height: 1px;
  width: 0%;
  background: #000;
  -webkit-transition: .3s ease;
  -o-transition: .3s ease;
  transition: .3s ease;
}

.menu li:hover span {
  width: 100%;
} 

.menu__logo {
  width: 150px;
  image-rendering: optimizeQuality;
}

/****** Mobile ******/

