.contact {
  height: 100vh;
  font-family: 'SF Movie Poster';
  overflow-x: hidden;
}

.contact__shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 50;
}

.contact__container {
  position: relative;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 60;
}

.contact__container .email {
  color: #000;
  z-index: 20;
  font-size: 60px;
  letter-spacing: 5px;
  line-height: 0.9;
  border-bottom: 1px solid #000;
  margin-bottom: 20vh;
}

.contact__tel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 40px;
  letter-spacing: 4px;
  line-height: 1.1;
  z-index: 20;
  text-align: center;
}

.contact__tel p {
  margin: 0;
}

.contact__tel .tel {
  color: rgb(62, 145, 173);
}

.signature {
  position: absolute;
  bottom: 10px;
  font-size: 22px;
  letter-spacing: 1.8px;
}

.smiley {
  z-index: 50;
  position: absolute;
  right: 25px;
  bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: .5s ease;
  -o-transition: .5s ease;
  transition: .5s ease;
}

.smiley .yeux {
  width: 15px;
  margin-bottom: 5px;
}

.smiley .bouche {
  width: 35px;
  /* clip-path: polygon(0 0, 100% 0, 50% 0, 50% 100%, 50% 100%, 50% 0); */
  -webkit-clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
          clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  -webkit-animation: bouche 5s ease infinite;
          animation: bouche 5s ease infinite;
}

/* from middle*/
/* @keyframes bouche {
  0% {
    clip-path: polygon(0 0, 100% 0, 50% 0, 50% 100%, 50% 100%, 50% 0);
  }
  15%, 100% {
    clip-path: polygon(0 0, 100% 0, 100% 0, 100% 100%, 0% 100%, 0% 0);
  }
} */

/*From left*/
@-webkit-keyframes bouche {
  0% {
    -webkit-clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
            clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  }
  36%, 100% {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}
@keyframes bouche {
  0% {
    -webkit-clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
            clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  }
  36%, 100% {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}

.plante {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 25%;
  z-index: 30;
}

/****** Mobile ******/

@media (max-width: 640px) {
  .contact {
    overflow: hidden;
    height: 100vh;
  }

  .contact__shadow {
    height: 100vh;
  }

  .contact__container {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    padding: 150px 15px 0 15px;
  }

  .contact__container .email {
    font-size: 30px;
    letter-spacing: 1.5px;
    margin-bottom: 6vh;
  }

  .contact__tel {
    font-size: 26px;
    letter-spacing: 2px;
    z-index: 40;
    line-height: 1;
  }

  .contact__tel p {
    line-height: 1;
    letter-spacing: 2px;
  }

  .contact__tel .lisa {
    padding-right: 10px;
    margin-right: 10px;
  }

  .contact__tel .tel {
    font-weight: bold;
  }

  .signature {
    font-size: 16px;
    line-height: 0.8;
    letter-spacing: 1.5px;
    z-index: 50;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    align-items: center;
    right: 25px;
  }

  .smiley {
    bottom: 50px;
    right: 72px;
  }
  
  .plante {
    width: auto;
    max-height: 40vh;
  }
}