.collectif {
  background: #fff;
  min-height: calc(100vh - 100px);
  -webkit-box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.3);
          box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.3);
}

.collectif__shadow {
  position: absolute;
  width: 100%;
  height: calc(100vh - 100px);
  z-index: 20;
}

.collectif__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: 'SF Movie Poster';
  height: 100%;
  position: relative;
  z-index: 20;
}

.collectif__container div:not(:last-of-type) {
  letter-spacing: 3px;
  line-height: 1;
  display: block;
  margin-bottom: 1.5vh;
}

.collectif__container div:nth-of-type(1) {
  font-size: 36pt;
  font-weight: 200;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.collectif__container div:nth-of-type(1)::after {
  content: '';
  position: absolute;
  top: 47px;
  width: 120px;
  left: 0px;
  border-bottom: 1px solid #000;
}

.collectif__container div:nth-of-type(2) {
  font-size: 28pt;
  font-weight: bold;
}

.faconne img {
  width: 88px;
  -webkit-transform: translateY(12px);
      -ms-transform: translateY(12px);
          transform: translateY(12px);
}

.collectif__container div:nth-of-type(3) {
  font-size: 28pt;
  font-weight: 200;
}

.collectif__container div:nth-of-type(4) {
  font-size: 32pt;
  font-weight: bold;
}

.collectif__container div:nth-of-type(5) {
  font-size: 22pt;
  font-weight: 200;
  letter-spacing: 2.5px;
}

.collectif__container div:nth-of-type(6) {
  font-size: 28pt;
  font-weight: 200;
  letter-spacing: 2.5px;
  text-align: center;
  margin-bottom: 50px;
}

.collectif__video {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.collectif__video .wip {
  width: 400px;
  height: 260px;
  -webkit-box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
          box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
}

.collectif__video .wip_hourglass {
  position: absolute;
  width: 6.8px;
  top: 166.3px;
  left: 174px;
  -webkit-animation: hourglass 4s ease infinite;
          animation: hourglass 4s ease infinite;
}

@-webkit-keyframes hourglass {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg)}
  50% { -webkit-transform: rotate(180deg); transform: rotate(180deg)}
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg)}
}

@keyframes hourglass {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg)}
  50% { -webkit-transform: rotate(180deg); transform: rotate(180deg)}
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg)}
}

/****** Mobile ******/

@media (max-width: 640px) {
  .collectif {
    height: 100vh;
    position: relative;
  }

  .collectif__shadow {
    height: 100vh;
  }

  .collectif__container div:nth-of-type(1) {
    font-size: 26pt;
  }

  .collectif__container div:nth-of-type(1)::after {
    top: 36px;
    width: 92px;
    left: 0px;
  }
  .collectif__container div:nth-of-type(2) {
    margin-top: 15px;
    font-size: 16pt;
  }

  .faconne img {
    width: 48px;
    -webkit-transform: translateY(6px);
        -ms-transform: translateY(6px);
            transform: translateY(6px);
  }

  .collectif__container div:nth-of-type(3) {
    font-size: 18pt;
  }
  .collectif__container div:nth-of-type(4) {
    font-size: 16pt;
    text-align: center;
    font-weight: bold;
    word-spacing: -4px;
  }
  .collectif__container div:nth-of-type(5) {
    font-size: 20pt;
  }
  .collectif__container div:nth-of-type(6) {
    font-size: 14pt;
    text-align: center;
    font-weight: bold;
  }

  .collectif__video .wip {
    width: 300px
  }

  .collectif__video .wip_hourglass {
    position: absolute;
    width: 6.8px;
    top: 166.2px;
    left: 129px;
    -webkit-filter: blur(0);
          filter: blur(0);
  }
}