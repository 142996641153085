.friends {
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: 'SF Movie Poster';
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 60;
  left: 100%;
  -webkit-transition: .5s ease;
  -o-transition: .5s ease;
  transition: .5s ease;
}

.friends__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.friends__left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  letter-spacing: 5px;
}

.friends__left > span {
  font-size: 64px;
  border-bottom: 1px solid #000;
  line-height: 0.9;
}

.friends__right {
  font-size: 28px;
  line-height: 1;
  letter-spacing: 2px;
  border-left: 1px solid #000;
  padding-left: 15px;
  margin-left: 15px;
}

.friends__link {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.friends a {
  color: #000;
  -webkit-transition: .3s ease;
  -o-transition: .3s ease;
  transition: .3s ease;
  line-height: 1;
}

.friends__link span {
  height: 1px;
  width: auto;
  background-color: #000;
  display: block;
  -webkit-transition: .5s ease;
  -o-transition: .5s ease;
  transition: .5s ease;
  -webkit-transform: scaleX(0);
      -ms-transform: scaleX(0);
          transform: scaleX(0);
}

.friends__link:hover span {
  -webkit-transform: scaleX(1);
      -ms-transform: scaleX(1);
          transform: scaleX(1);
}

/****** Mobile ******/

@media (max-width: 640px) {

  .friends__container {
    padding: 0 20px;
  }

  .friends__left {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    width: 20%;
  }

  .friends__left > img {
    width: 30px;
  }

  .friends__left > span {
    font-size: 34px;
    border-bottom: 1px solid #000;
    line-height: 0.9;
    letter-spacing: 3.5px;
  }

  .friends__right {
    font-size: 24px;
    line-height: 1.2;
    letter-spacing: 0.8px;
    border-left: 1px solid #000;
    padding-left: 5px;
    margin-left: px;
  }

  .friends a:hover {
    color: #000000;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}