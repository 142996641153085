.projet {
  border: 2px solid #000;
  background-size: cover;
  background-position: center;
  z-index: 30;
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  overflow: hidden;
  -webkit-box-shadow: 1px 1px 6px rgba(0,0,0,0.5);
          box-shadow: 1px 1px 6px rgba(0,0,0,0.5);
}

.projet__bottom {
  font-family: 'SF Movie Poster';
  letter-spacing: 2px;
  font-size: 30pt;
  background-color: rgba(255,255,255,0.5);
  line-height: 1.8;
  color: #000;
  opacity: 0;
  -webkit-transition: .5s ease;
  -o-transition: .5s ease;
  transition: .5s ease;
  -webkit-transform: translateY(100px);
      -ms-transform: translateY(100px);
          transform: translateY(100px);
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.projet:hover .projet__bottom {
  opacity: 1;
  -webkit-transform: translateY(0px);
      -ms-transform: translateY(0px);
          transform: translateY(0px);
}


/****** Mobile ******/

@media (max-width: 640px) {

  .projet {
    border: 0;
    justify-content: flex-end;
  }

  .projet__bottom {
    font-size: 22pt;
    opacity: 1;
    -webkit-transform: translateY(0px);
        -ms-transform: translateY(0px);
            transform: translateY(0px);
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(255,255,255,0.8830882694874825) 45%);
    height: 60px;
    line-height: 1.2;
    align-items: flex-end;
  }
}